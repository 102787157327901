import { Publication } from "@libry-content/types";
import groq from "groq";

export const resolvePublicationSummary = groq`
  _type,
  image,
  title,
  author,
  publicationYear,
  mediaTypes,
  workType
`;

export type PublicationSummary = Pick<
  Publication,
  "image" | "title" | "_type" | "author" | "publicationYear" | "mediaTypes" | "workType"
>;
