// Source: https://stackoverflow.com/a/51399781/1540547
export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

export type NullableArrayElement<ArrayType extends readonly unknown[] | undefined | null> = ArrayElement<
  NonNullable<ArrayType>
>;

/**
 * Get the keys from an object, typing them in the process
 */
export const objectKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>;

/**
 * Useful to pick different fields on parts of a union type where parts are separated using Extractor
 */
export type PickFromExtracted<
  T,
  Extractor extends Partial<T>,
  FieldsOnExtracted extends keyof Extract<T, Extractor>,
  FieldsOnExcluded extends keyof Exclude<T, Extractor>
> = Pick<Extract<T, Extractor>, FieldsOnExtracted> | Pick<Exclude<T, Extractor>, FieldsOnExcluded>;

export type ValueOf<T> = T[keyof T];

// Source: https://stackoverflow.com/a/66252656/1540547
export type ExcludeStringIndexSignature<T extends object> = {
  [Key in keyof T as string extends Key ? never : Key]: T[Key];
};

// https://stackoverflow.com/a/41980288
export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};
