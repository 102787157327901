import { Modify } from "@biblioteksentralen/types";
import { Affiliation, Employee, Municipality, Site } from "@libry-content/types";
import {
  ResolvedLibrarySummary,
  resolveLibrarySummary,
} from "../../../../../apps/frontend/src/components/library/sanityQuery";
import groq from "groq";

const resolveAffiliation = groq`
  _type,
  _key,
  entity -> {
    ${resolveLibrarySummary}
  }
`;

type ResolvedAffiliation = Pick<Affiliation, "_type"> & {
  _key: string;
  entity: ResolvedLibrarySummary;
};

export const resolveEmployeeGroqProjection = groq`
  ...,
  affiliations[] { ${resolveAffiliation} }
`;

export type ResolvedEmployee = Modify<
  Employee,
  {
    affiliations?: ResolvedAffiliation[];
  }
>;

export const resolveEmployeeSummaryGroqProjection = groq`
  _type,
  _id,
  name,
  image,
  showEmployee,
  position,
  affiliations[] { ${resolveAffiliation} }
`;

export type ResolvedEmployeeSummary = Pick<
  Employee,
  "_id" | "_type" | "name" | "image" | "showEmployee" | "position"
> & {
  affiliations?: ResolvedAffiliation[];
};

export const resolveCreatedBySummary = groq`
 {
    _type == "employee" => {
      ${resolveEmployeeSummaryGroqProjection}
    },
    _type == "library" => {
      ${resolveLibrarySummary}
    },
  }
`;

export type ResolvedSiteSummary = Pick<Site, "_id" | "_type" | "name"> & {
  coatOfArms: Municipality["coatOfArms"] | undefined;
};

export type ResolvedCreatedBySummary = ResolvedEmployeeSummary | ResolvedLibrarySummary | ResolvedSiteSummary;
