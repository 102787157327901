import { List } from "@libry-content/types";
import groq from "groq";
import { resolveCreatedBySummary, ResolvedCreatedBySummary } from "../../employees";
import { PublicationSummary, resolvePublicationSummary } from "../../recommendations/groq/resolvePublication";

export const resolveListGroqProjecton = groq`
  ...,
  createdBy -> ${resolveCreatedBySummary}
`;

export type ResolvedList = List & { createdBy?: ResolvedCreatedBySummary };

export const resolveListSummary = groq`
  _id,
  _type,
  _createdAt,
  teaser,
  title,
  targetAudiences,
  description,
  promotion,
  publications[defined(image)] [0...3] {
    ${resolvePublicationSummary}
  },
  createdBy -> ${resolveCreatedBySummary},
  "mediaTypes": array::unique(publications[].mediaTypes[]),
  "workTypes": array::unique(publications[].workType)
`;

export type ResolvedListSummary = Pick<
  List,
  "_type" | "_id" | "_createdAt" | "teaser" | "title" | "targetAudiences" | "description" | "promotion"
> & {
  publications?: PublicationSummary[];
  createdBy?: ResolvedCreatedBySummary;
  mediaTypes?: string[];
  workTypes?: PublicationSummary["workType"][];
};
