import { Box, colors, Link, Text } from "@biblioteksentralen/react";
import { splashSiteUrl } from "@libry-content/common";
import onDesktop from "../../utils/onDesktop";
import { useSitePalette } from "../../utils/useSitePalette";
import { HeaderFooterContainer } from "../ContentContainer";

export const UnknownDomainHeader = () => {
  const paletteColor = useSitePalette().colors.header;

  return (
    <Box
      as="header"
      borderBottom={paletteColor.isBright ? `${colors.grey15} solid 0.15rem` : undefined}
      {...paletteColor.css}
    >
      <HeaderFooterContainer padding="1rem 1.75rem" fontSize="0.9rem" gridGap="2rem" textDecoration="none">
        <Link href={splashSiteUrl.toString()} textDecoration="none" _hover={onDesktop({ textDecoration: "underline" })}>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <Text as="span" fontWeight="bold">
            LIBRY
          </Text>{" "}
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <Text as="span">CONTENT</Text>
        </Link>
      </HeaderFooterContainer>
    </Box>
  );
};
